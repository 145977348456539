import { isEmpty } from "lodash";
import { ApiService } from ".";
import authService from "../components/api-authorization/AuthorizeService";
import { IApiResponse, IDocument, IDocumentResult, IParameters, IRenderTree, ISaveResponse, ISearchParameters, ISearchResult } from "../models";
import { getCookie } from "../tools";

export class DocumentService implements ApiService<IDocument> {
    async get(parameters: IParameters): Promise<IDocument | null> {
        try {
            const token = await authService.getAccessToken();
            const id = parameters['id'] as string;
            delete parameters['id'];
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Document/${id}?parameters=${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve(null);
        }
    }
    async search(parameters: ISearchParameters): Promise<ISearchResult<IDocument>> {
        const { numberPerPage, pageNumber } = parameters;
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Document/search?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve({
                total: 0,
                items: [],
                pageNumber,
                pageTotal: 0,
                numberPerPage,
                startIndex: 0,
                endIndex: 0,
            });
        }
    }

    async create(data: IDocument, callback?: (response: ISaveResponse<IDocument>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/Document`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async update(id: string, data: IDocument, callback?: (response: ISaveResponse<IDocument>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/Document/${id}`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async searchResult(parameters: ISearchParameters): Promise<ISearchResult<IDocumentResult>> {
        const results = await this.search(parameters);
        const items: IDocumentResult[] = [...results.items].map(i => ({
            id: i.id,
            key: i.key,
            name: i.name,
            lineno: i.lineno,
            type: i.type?.name || '',
            status: i.status,
            paths: i.pathGroups
                ? i.pathGroups?.map(group =>
                    ({ items: group.items.map(i => `${i.dataType.name}: ${i.dataName?.name}`) }))
                : null,
            permission: i.permission,
            signContractDate: i.signContractDate,
            expiredDate: i.expiredDate,
            barcode: i.barcode,
            hasPdf: i.pdfFileName ? true : false,
            hasFiles: isEmpty(i.fileNames) ? false : true,
            abstract: i.abstract,
        }));

        return { ...results, items };
    }

    async uploadPdf(id: string, file: File, callback?: (response: ISaveResponse<any>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const formData = new FormData();
            formData.append('file', file);
            const response = await fetch(`api/Document/upload/${id}/pdf`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: formData,
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async uploadFiles(id: string, files: File[], callback?: (response: ISaveResponse<any>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append(`file-{}`, files[i]);
            }

            const response = await fetch(`api/Document/upload/${id}/files`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: formData,
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async treeResult(parameters: IParameters): Promise<ISearchResult<IRenderTree>> {
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();

            const response = await fetch(`api/Document/path/tree?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve({
                total: 0,
                items: [],
                pageNumber: 1,
                pageTotal: 0,
                numberPerPage: 1,
                startIndex: 0,
                endIndex: 0,
            });
        }
    }

    async inactives(docIds: string[], callback?: (response: ISaveResponse<any>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/Document/inactives`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify({ docIds }),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async actives(docIds: string[], callback?: (response: ISaveResponse<any>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/Document/actives`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify({ docIds }),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async changeLineNo(id: string, lineno: string, callback?: (response: ISaveResponse<any>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/Document/lineno/${id}`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify({ lineno }),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async changeBarcode(id: string, barcode: string, callback?: (response: ISaveResponse<any>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/Document/barcode/${id}`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify({ barcode }),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async getNextLineNo(parameters: IParameters, callback?: (response: IApiResponse<string>) => void): Promise<string | null> {
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Document/lineno?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();

            return json.data;
        } catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }

            return await Promise.resolve("1");
        }
    }
}
