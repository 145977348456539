import { IBasePickerStyles, ITag, ITagPickerProps, TagPicker } from "@fluentui/react/lib/Pickers";
import { ISearchParameters } from "../../models";
import { ApiService } from "../../services";
import { isEmpty } from "../../tools";
import { useId } from "@fluentui/react-hooks";
import { ILabelProps, Label } from "@fluentui/react/lib/Label";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { useContext } from "react";
import { AppContext } from "../../contexts";

export interface IModel {
    key: string;
    name?: string | null;
}

export interface IPickerItem<T> extends ITag {
    data?: T;
}

export interface PickerProps<T> {
    service?: ApiService<T>;
    tagPickerProps?: ITagPickerProps;
    pickerStyles?: Partial<IBasePickerStyles>;
    pickOnlyOne?: boolean;
    placeholder?: string;
    disabled?: boolean;
    removeDuplicateKey?: boolean;
    selectedItems?: IPickerItem<T>[];
    maximumResults?: number;
    label?: string;
    labelProps?: ILabelProps;
    required?: boolean;
    defaultSelectedItems?: IPickerItem<T>[];
    displayOnFocus?: boolean;
    minimumTextLength?: number;
    onResolveSuggestions?: (filter: string, selectedItems?: IPickerItem<T>[] | undefined, service?: ApiService<T> | undefined) => IPickerItem<T>[] | PromiseLike<IPickerItem<T>[]>;
    onMapResults?: (data: T[]) => IPickerItem<T>[];
    onMapFilter?: (filter: string, defaultFilter: ISearchParameters) => ISearchParameters;
    onSelect?: (item: IPickerItem<T> | undefined) => IPickerItem<T> | Promise<IPickerItem<T>> | null;
    onChange?: (item: IPickerItem<T>[] | undefined) => void | Promise<void> | undefined;
}

const pickerOnlyOneClassName = mergeStyles({
    '& span.ms-BasePicker-itemsWrapper': {
        width: '100%',
    },
    '& .ms-TagItem': {
        width: '100%',
        maxWidth: '100%',
        '& .ms-TagItem-text': {
            flexGrow: 1
        }
    }
});

const listContainsTagList = <T extends IModel>(item: IPickerItem<T>, selectedItems?: IPickerItem<T>[]) => {
    if (!selectedItems || !selectedItems.length || selectedItems.length === 0) {
        return false;
    }

    return selectedItems.some(compareTag => compareTag.key === item.key);
};

export const Picker = <T extends IModel>(props: PickerProps<T>) => {
    const { profile } = useContext(AppContext);
    const pickerId = useId('inline-picker');

    const {
        service,
        pickOnlyOne = false,
        disabled = false,
        removeDuplicateKey = true,
        selectedItems,
        placeholder,
        pickerStyles,
        maximumResults = 100,
        label,
        labelProps,
        required,
        defaultSelectedItems,
        displayOnFocus = true,
        minimumTextLength,
        onSelect,
        onChange,
        onResolveSuggestions,
        onMapFilter,
        onMapResults,
    } = props;

    const filterDuplicate = (items: IPickerItem<T>[], selectedItems?: IPickerItem<T>[]) => {
        const filterItems = [...items];
        return filterItems.filter(i => !listContainsTagList(i, selectedItems));
    };

    const onDefaultMapResults = (data: T[]): IPickerItem<T>[] => {
        if (!data || isEmpty(data)) {
            return [];
        }

        return data.map(d => ({ key: d.key, name: (d.name ? d.name : d.key), data: d }));
    }

    const _onResolveSuggestions = (filter: string, selectedItems?: IPickerItem<T>[] | undefined) => {
        if (minimumTextLength && (!filter || filter.length < minimumTextLength)) {
            return [];
        }

        if (onResolveSuggestions) {
            return onResolveSuggestions(filter, selectedItems, service);
        }

        if (service) {
            const defaultFilter = {
                keyword: filter,
                pageNumber: 1,
                numberPerPage: maximumResults,
                status: 'active',
                siteId: profile?.site?.id,
            };

            const mappedFilter = onMapFilter
                ? onMapFilter(filter, { ...defaultFilter })
                : { ...defaultFilter };

            const promise = service.search(mappedFilter);
            return promise.then(results => {
                const items = onMapResults ? onMapResults(results.items) : onDefaultMapResults(results.items);
                if (items && removeDuplicateKey) {
                    return filterDuplicate(items, selectedItems)
                }

                return items;
            });
        }

        return [];
    }

    const tagPickerProps: ITagPickerProps = {
        onResolveSuggestions: _onResolveSuggestions,
        inputProps: {
            placeholder: placeholder || 'Select',
            id: pickerId,
        },
        styles: pickerStyles,
    }

    if (pickOnlyOne) {
        tagPickerProps.itemLimit = 1;
        tagPickerProps.className = pickerOnlyOneClassName;
    }

    if (displayOnFocus) {
        tagPickerProps.onEmptyResolveSuggestions = (selecteItems) => _onResolveSuggestions('', selecteItems);
    }

    const renderPicker = () => {
        return (
            <TagPicker
                {...tagPickerProps}
                onItemSelected={onSelect}
                defaultSelectedItems={defaultSelectedItems}
                onChange={onChange}
                disabled={disabled}
                selectedItems={selectedItems}
                resolveDelay={300}
            />
        );
    }

    return (
        <>
            {label ? (
                <div className='ms-TextField-wrapper'>
                    <Label {...labelProps} required={required} htmlFor={pickerId} disabled={disabled}>{label}</Label>
                    {renderPicker()}
                </div>
            ) : renderPicker()}

        </>
    );
};
